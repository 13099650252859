@media screen and (min-width: 769px) {

  html,
  body,
  .toplevel {
    height: 100vh;
    height: -webkit-fill-available;
    overflow: hidden;
  }

  .toplevel {
    display: flex;
    flex-direction: column;
  }

  .statistics-chart-area {
    padding: 1em;
  }
}

@media not screen and (min-width: 1024px) {
  .statistics-chart-area {
    height: 300px;
  }
}

#lumiguide-logo {
  width: 10em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0;
}

// Facility map

.facility-map-svg {
  flex: 1;

  .facility-map-spot {
    &.is-upper-place {
      stroke-dasharray: 5, 5;
    }
  }
}

.occupation-details-image {
  width: 100%;
}

.occupation-details-image-unavailable {
  width: 50%;
}

.statistics-controls>div {
  margin: 1em;
}

.abandoned-vehicle-report .image {
  width: 100%;
  max-height: 500px;
}

@media print {
  @page {
    margin: 0mm;
  }

  body {
    width: 1024px;
    margin: 15mm;
  }

  .navbar {
    display: none;
  }
}