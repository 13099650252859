@use 'lumi-bulma/src/css/lumi-bulma-prefers-dark' as lumi-bulma

// Start at inherited fill color
@keyframes facility-map-blink-fill
  50%
    fill: black
  100%
    fill: white

@keyframes facility-map-blink-stroke
  0%
    stroke: black
  100%
    stroke: white

.facility-map-svg
  stroke-width: 1.5
  stroke: black

  .facility-map-background
    fill: lumi-bulma.$grey-light

  .facility-map-spot
    fill: beige

    &.is-active
      rect, polygon
        animation: facility-map-blink-fill 700ms ease-in 0s infinite alternate !important

    &.is-group-active
      rect, polygon
        animation: facility-map-blink-stroke 700ms ease-in 0s infinite alternate !important

    &.is-active.is-group-active
      rect, polygon
        animation: facility-map-blink-stroke 700ms ease-in 0s infinite alternate, facility-map-blink-fill 700ms ease-in 0s infinite alternate !important

    $facility-map-spot-statuses: ( "free": lumi-bulma.$free, "occupied": lumi-bulma.$occupied, "abandoned": lumi-bulma.$occupied, "error": lumi-bulma.$error)

    @each $status, $fill in $facility-map-spot-statuses
      &.facility-map-spot-#{$status}
        fill: $fill

    .facility-map-spot-label
      stroke: none
      fill: black

    &:hover
      fill: black !important
      .facility-map-spot-label
        fill: lumi-bulma.$info !important

  .facility-map-device-camera
    fill: beige

    .facility-map-device-camera-dot
      stroke: black

    .facility-map-device-camera-cone
      stroke: black
      fill-opacity: 0.5
      pointer-events: none !important

    .facility-map-device-camera-label
      font-weight: 700
      stroke: none
      fill: black

    &.is-active
      .facility-map-device-camera-dot, .facility-map-device-camera-cone
        animation: facility-map-blink-stroke 700ms ease-in 0s infinite alternate !important
      .facility-map-device-camera-label
        animation: facility-map-blink-fill 700ms ease-in 0s infinite alternate !important

    &:hover
      .facility-map-device-camera-dot, .facility-map-device-camera-cone
        stroke: white !important
      .facility-map-device-camera-label
        fill: lumi-bulma.$info !important

  .facility-map-indicator
    fill: beige

    .facility-map-indicator-body
      stroke: black

    .facility-map-indicator-label
      font-weight: 700
      stroke: none
      fill: black

    &.is-active
      .facility-map-indicator-body
        animation: facility-map-blink-stroke 700ms ease-in 0s infinite alternate !important
      .facility-map-indicator-label
        animation: facility-map-blink-fill 700ms ease-in 0s infinite alternate !important

    &:hover
      .facility-map-indicator-body
        stroke: white !important
      .facility-map-indicator-label
        fill: lumi-bulma.$info !important

  .facility-map-display
    fill: beige

    .facility-map-display-orientation
      stroke: red
      fill: none
      pointer-events: none !important

    .facility-map-display-rect
      stroke: black

    .facility-map-display-label
      font-weight: 700
      stroke: none
      fill: black

    &.is-active
      .facility-map-display-rect
        animation: facility-map-blink-stroke 700ms ease-in 0s infinite alternate !important
      .facility-map-display-label
        animation: facility-map-blink-fill 700ms ease-in 0s infinite alternate !important

    &:hover
      .facility-map-display-rect
        stroke: white !important
      .facility-map-display-label
        fill: lumi-bulma.$info !important
