@use 'lumi-bulma/src/css/lumi-bulma-prefers-dark' as lumi-bulma

.place-model-base
  stroke-width: 3
  stroke: blue
  fill-opacity: 0

.place-model-projection
  stroke-dasharray: 5, 5

.place-model-click-target
  stroke-width: 30
  stroke-opacity: 0

.place-model-side
  stroke-width: 3
  stroke: red
  pointer-events: none

.place-model-up
  stroke-width: 3
  stroke: green
  pointer-events: none

.place-model-circle
  stroke-width: 2
  stroke: black
  fill: white
  fill-opacity: 0.6

.place-model-arc
  stroke-width: 4
  stroke: white
  fill: black
  fill-opacity: 0.4

  .place-model-arc-edge
    fill: none !important

  .place-model-arc-fill
    stroke: none !important

.place-model-base.free
    stroke: lumi-bulma.$free

.place-model-base.occupied
    stroke: lumi-bulma.$occupied

.place-model-base.error
    stroke: lumi-bulma.$error

.place-model.is-disabled
  .place-model-base, .place-model-side, .place-model-up, .place-model-circle, .place-model-arc, .place-model-polyline
    stroke-opacity: 0.3
    fill-opacity: 0.1

.place-model-base, .place-model-side, .place-model-up, .place-model-circle, .place-model-arc, .place-model-polyline
  &.is-disabled
    stroke-opacity: 0.3
    fill-opacity: 0.1
